import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuperAdminGuard } from './services/guards/admin/super-admin.guard';
import { AuthGuard } from './services/guards/auth/auth.guard';
import { PublicGuard } from './services/guards/public/public.guard';
import { memberGuard } from './services/guards/member/member.guard';
import { TestingPageComponent } from './testing-page/testing-page.component';
import { TeamGatewayComponent } from './team-gateway/team-gateway.component';

const mainRoutes: Routes = [
  {
    path: 'salons',
    loadChildren: () =>
      import('./salons/salons.routes').then((m) => m.SalonsRoutes),
  },
  {
    path: 'app-gateway',
    component: TeamGatewayComponent,
  },
  {
    path: 'quiz',
    loadChildren: () =>
      import('./questionnaire/questionnaire.routes').then(
        (m) => m.QuestionnaireRoutes
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import('./landing-page/landing-page.component').then(
        (m) => m.LandingPageComponent
      ),
    canActivate: [PublicGuard],
    data: { type: 'E' },
  },
  {
    path: 'admin',
    loadComponent: () =>
      import('./admin/admin.component').then((m) => m.adminComponent),
    canActivate: [SuperAdminGuard],
  },

  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then((m) => m.LoginComponent),
    canActivate: [PublicGuard],
  },
  {
    path: 'test_deep_link',
    component: TestingPageComponent,
  },
  {
    path: 'quizfinished/:mode',
    component: TestingPageComponent,
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./register/register.component').then((m) => m.RegisterComponent),
    canActivate: [PublicGuard],
  },
  {
    path: 'reset-pass/:token',
    loadComponent: () =>
      import('./password-reset/password-reset.component').then(
        (m) => m.PasswordResetComponent
      ),
  },
  {
    path: 'unlockCheat/:token',
    loadComponent: () =>
      import('./dashboard/unlock-cheat/unlock-cheat.component').then(
        (m) => m.UnlockCheatComponent
      ),
  },
  {
    path: 'kids',
    loadComponent: () =>
      import('./dashboard/getkids/getkids.component').then(
        (m) => m.GetkidsComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadComponent: () =>
      import('./account/account.component').then((m) => m.AccountComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'openticket',
    loadComponent: () =>
      import('./tickets/ticket-opener/ticket-opener.component').then(
        (m) => m.TicketOpenerComponent
      ),
  },
  {
    path: 'tickets',
    loadComponent: () =>
      import('./tickets/ticket-viewer/ticket-viewer.component').then(
        (m) => m.TicketViewerComponent
      ),
    canActivate: [memberGuard],
  },
  {
    path: 'unauthorized',
    loadComponent: () =>
      import('./not-authorized/not-authorized.component').then(
        (m) => m.NotAuthorizedComponent
      ),
  },
  {
    path: 'app-mind',
    loadChildren: () => import('./mind/mind.routes').then((m) => m.MindRoutes),
  },
  {
    path: 'kidview',
    loadChildren: () =>
      import('./dashboard/kidview.routes').then((m) => m.KidviewRoutes),
    data: { kidview: true },
  },
  {
    path: 'dash',
    loadChildren: () =>
      import('./dashboard/dashboard.routes').then((m) => m.DashboardRoutes),
    canActivate: [AuthGuard],
    data: { kidview: false },
  },
  // { path: 'token', component: TokenComponent, canActivate: [AuthGuard] },
  {
    path: 'downloads',
    loadComponent: () =>
      import('./downloads/downloads.component').then(
        (m) => m.DownloadsComponent
      ),
    // canActivate: [AuthGuard],
  },
  {
    path: 'forums',
    loadComponent: () =>
      import('./forumsComponents/forums/forums.component').then(
        (m) => m.ForumsComponent
      ),
  },
  {
    path: 'image-approval/:img/:blogid',
    loadComponent: () =>
      import('./blogs/image-approval/image-approval.component').then(
        (m) => m.ImageApprovalComponent
      ),
  },
  {
    path: 'articles',
    loadComponent: () =>
      import('./articles/articles.component').then((m) => m.ArticlesComponent),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./contact/contact.component').then((m) => m.ContactComponent),
  },
  {
    path: 'nous',
    loadComponent: () =>
      import('./team/team.component').then((m) => m.TeamComponent),
  },
  {
    path: 'encourage',
    loadComponent: () =>
      import(
        './dashboard/emailViews/email-feedback/email-feedback.component'
      ).then((m) => m.EmailFeedbackComponent),
  },

  {
    path: 'SuccessFromEmail',
    loadComponent: () =>
      import('./redirect-from-email/redirect-from-email.component').then(
        (m) => m.RedirectFromEmailComponent
      ),
    canActivate: [PublicGuard],
  },
  {
    path: 'legalmentions',
    loadComponent: () =>
      import('./legal-mentions/legal-mentions.component').then(
        (m) => m.LegalMentionsComponent
      ),
  },

  {
    path: 'rgpd',
    loadComponent: () =>
      import('./rgpd/rgpd.component').then((m) => m.RgpdComponent),
  },
  {
    path: 'cgvu',
    loadComponent: () =>
      import('./cgvu/cgvu.component').then((m) => m.CGVUComponent),
  },
  {
    path: 'politics',
    loadComponent: () =>
      import('./politics/politics.component').then((m) => m.PoliticsComponent),
  },
  {
    path: 'app-cgvu',
    data: { mobile: true },
    loadComponent: () =>
      import('./cgvu/cgvu.component').then((m) => m.CGVUComponent),
  },
  {
    path: 'app-delete-kid',
    loadComponent: () =>
      import('./webview/restore-kid/verify-token/verify-token.component').then(
        (m) => m.VerifyTokenComponent
      ),
    data: { delete: true },
  },
  {
    path: 'app-restore-kid',
    loadComponent: () =>
      import('./webview/restore-kid/verify-token/verify-token.component').then(
        (m) => m.VerifyTokenComponent
      ),
    data: { delete: false },
  },
  {
    path: 'app-politics',
    data: { mobile: true },
    loadComponent: () =>
      import('./politics/politics.component').then((m) => m.PoliticsComponent),
  },
  {
    path: ':lang/quizVerification/:device',
    loadComponent: () =>
      import('./questionnaire/verify-quizz/verify-quizz.component').then(
        (m) => m.VerifyQuizzComponent
      ),
  },
  {
    path: 'quizVerification/:device',
    loadComponent: () =>
      import('./questionnaire/verify-quizz/verify-quizz.component').then(
        (m) => m.VerifyQuizzComponent
      ),
  },
  {
    path: 'validateChallenge/:id',
    loadComponent: () =>
      import('./challenge-verification/challenge-verification.component').then(
        (m) => m.ChallengeVerificationComponent
      ),
  },
];
// const allRoutes: Routes = mainRoutes.concat([
//   {
//     path: '**',
//     loadComponent: () =>
//       import('./not-found/not-found.component').then(
//         (m) => m.NotFoundComponent
//       ),
//     pathMatch: 'full',
//   },
// ]);
export const routes: Routes = [
  // Language-aware routes
  {
    path: ':lang',
    children: mainRoutes,
  },
  // Routes without language prefix
  ...mainRoutes,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
